import { IDrawerMenuItem } from "../types/types";
import { EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqUser, Nominee } from "../constants/roles";

import {
	CSSObject,
	CssBaseline,
	Divider,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Snackbar,
	StyledEngineProvider,
	Theme,
	ThemeProvider,
	Toolbar,
	Typography,
	Tooltip,
} from "@mui/material";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DrawerMenu } from "./DrawerMenu";
import { Link } from "react-router-dom";
import ListItemLink from "./Shared/ListItemLink";
import { LoginMenu } from "../components/api-authorization/LoginMenu";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { getSettings } from "../modules/Settings/settings.actions";
import lightTheme from "./Themes/light-theme";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { UserContext, UserContextType } from "../modules/User/UserContext";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { mailingSubmissionsFeatureEnabled } from "../App";

const PREFIX = "Layout";

const classes = {
	root: `${PREFIX}-root`,
	appBar: `${PREFIX}-appBar`,
	appBarShift: `${PREFIX}-appBarShift`,
	menuButton: `${PREFIX}-menuButton`,
	hide: `${PREFIX}-hide`,
	toolbar: `${PREFIX}-toolbar`,
	content: `${PREFIX}-content`,
	SecondaryMenuItemLink: `${PREFIX}-SecondaryMenuItemLink`,
	title: `${PREFIX}-title`,
	AppBarMenuSwitch: `${PREFIX}-AppBarMenuSwitch`,
	SubHeadText: `${PREFIX}-SubHeadText`,
	ImgPosition: `${PREFIX}-ImgPosition`,
};

const StyledBox = styled(Box)(({ theme: Theme }) => ({
	[`& .${classes.root}`]: {
		display: "flex",
		flexGrow: 1,
	},

	[`& .${classes.appBarShift}`]: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: Theme.transitions.create(["width", "margin"], {
			easing: Theme.transitions.easing.sharp,
			duration: Theme.transitions.duration.enteringScreen,
		}),
	},

	[`& .${classes.menuButton}`]: {
		marginRight: 36,
	},

	[`& .${classes.hide}`]: {
		display: "none",
	},

	[`& .${classes.toolbar}`]: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: Theme.spacing(0, 1),
		// necessary for content to be below app bar
		...Theme.mixins.toolbar,
	},

	[`& .${classes.content}`]: {
		flexGrow: 1,
	},

	[`& .${classes.title}`]: {
		flexGrow: 1,
		lineHeight: "normal",
	},

	[`& .${classes.AppBarMenuSwitch}`]: {
		marginLeft: 0,
	},
	[`& .${classes.SubHeadText}`]: {
		color: "#939598",
		fontSize: "2.2rem",
		marginLeft: "0.5rem",
	},
	[`& .${classes.ImgPosition}`]: {
		position: "relative",
		bottom: "0.4rem",
	},
}));

declare module "@mui/styles/defaultTheme" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme { }
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

declare module "@mui/styles/defaultTheme" {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme { }
}

const Alert = React.forwardRef(function Alert(props: AlertProps, ref: any) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer)<{ open?: boolean }>(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export function Layout(props: any) {
	const { isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
	const theme = useTheme();
	const dispatch = useDispatch();
	const alerts: any = useSelector((state: any) => state.alerts);
	const [open, setOpen] = React.useState(false);
	const [alertOpen, setAlertOpen] = React.useState(false);
	const settingsState: any = useSelector((state: any) => state.settings);

	useEffect(() => {
		if (isAuthenticated) {
			setOpen(true);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		dispatch(getSettings());
	}, [dispatch]);

	useEffect(() => {
		if (alerts.alert) {
			setAlertOpen(!alerts.alert.hidden);
		}
	}, [alerts.alert]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setAlertOpen(false);
	};

	return (
		<StyledBox sx={{ display: "flex" }}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={lightTheme}>
					<CssBaseline />
					<AppBar position="fixed" open={open}>
						<Toolbar>
							<a href="/">
								<img className={classes.ImgPosition} alt="" src="../../img/epiq-logo-white.png" />
								<span className={classes.SubHeadText}>filing</span>
							</a>
							<Switch>
								<Route exact path="/">
									<Typography variant="h6" line-height="normal" className={classes.title} noWrap></Typography>
								</Route>
								<Route>
									<Typography variant="h6" className={classes.title} noWrap></Typography>
								</Route>
							</Switch>
							<LoginMenu />
						</Toolbar>
					</AppBar>
					<Drawer variant="permanent" open={open}>
						<DrawerHeader>
							<div className={classes.toolbar}>
								<IconButton onClick={handleDrawerClose} size="large">
									{theme?.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
								</IconButton>
							</div>
						</DrawerHeader>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="end"
							sx={{
								marginRight: "0",
								...(open && { display: "none" }),
							}}
							size="large"
						>
							<MenuIcon />
						</IconButton>

						<Divider />

						<List style={{ marginLeft: 6 }}>
							{isAuthenticated && DrawerMenu.DrawerMenuItems.map((item: IDrawerMenuItem, index) => {
								if (item.Route.toLowerCase().indexOf("template") >= 0 && !isInRole([EpiqUser])) {
									// if this is a "template" route and the user is NOT an epiq user, don't show it
									return null;
								}
								else if (item.Route.toLowerCase().indexOf("mailinglist") >= 0 && !mailingSubmissionsFeatureEnabled.value) {
									// if this is a "mailinglist" route and the mailing list feature is disabled, don't show it
									return null;
								}
								else if (item.Route.toLowerCase().indexOf("reports") >= 0 && isInRole([Nominee])) {
									// if this is a "report" route and the user is nominee user, don't show it
									return null;
								}
								else if (item.Route.toLowerCase().indexOf("admin") === -1) {
									//if this isn't an admin route
									return (
										<ListItemLink
											id={"drawerItem_" + item.DisplayText.toLowerCase()}
											icon={item.IconName}
											primary={item.DisplayText}
											to={item.Route}
											key={index}
											tooltip={item.Tooltip}
										/>
									);
								} else if (
									isAuthenticated &&
									isInRole([EpiqAdmin, EpiqProjectManager, EpiqTechSupport])
								) {
									return (
										<ListItemLink
											id={"drawerItem_" + item.DisplayText.toLowerCase()}
											icon={item.IconName}
											primary={item.DisplayText}
											to={item.Route}
											key={index}
											tooltip={item.Tooltip}
										/>
									);
								}
							})}
						</List>

						<Divider />

						<List style={{ marginLeft: 6 }}>
							{DrawerMenu.SecondaryMenuItems.map((item: IDrawerMenuItem, index) => (

								(!item.Route.toLowerCase().includes("/termsofuse"))
									?
									(
										<ListItem
											className={classes.SecondaryMenuItemLink}
											button
											key={index}
											onClick={() => {
												window.open(item.Route, '_blank');
												return false;
											}}
											title={item.Tooltip}
										>
											<ListItemIcon>
												<Icon>{item.IconName}</Icon>
											</ListItemIcon>
											<ListItemText title={item.Tooltip} primary={item.DisplayText} />
										</ListItem>
									)
									:
									(
										<ListItem
											className={classes.SecondaryMenuItemLink}
											button
											key={index}
											component={Link}
											to={{ pathname: item.Route }}
											title={item.Tooltip}
										>
											<ListItemIcon>
												<Icon>{item.IconName}</Icon>
											</ListItemIcon>
											<ListItemText title={item.Tooltip} primary={item.DisplayText} />
										</ListItem>
									)
							))}
						</List>
					</Drawer>

					<DrawerHeader />

					<main className={classes.content}>
						<div className={classes.toolbar} />

						{props.children}
						{alerts.alert && (
							<Snackbar
								open={alertOpen}
								autoHideDuration={15000}
								onClose={handleAlertClose}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
							>
								<Alert severity={alerts.alert.severity} onClose={handleAlertClose} className={"muialert"}>
									{alerts.alert.message}
								</Alert>
							</Snackbar>
						)}
					</main>
				</ThemeProvider>
			</StyledEngineProvider>
		</StyledBox>
	);
}
