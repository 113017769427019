import React, { useEffect, useRef, useState } from 'react';
import {
	Theme,
	InputLabel,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


interface SecuritiesScrollableTableProps {
	id: string,
	label?: string;
	data: string[];
	tableWidth?: number;
	numberOfRowsToDisplay?: number;
	fontSize?: number,
	padding?: number;
	margin?: number;
	noResultsText?: string;
}

const DEFAULT_TABLE_WIDTH =  400;
const DEFAULT_ROW_HEIGHT = 40;
const DEFAULT_FONT_SIZE = 16;
const DEFAULT_PADDING = 8;
const DEFAULT_MARGIN = 4;
const DEFAULT_LINE_HEIGHT = 1.5;
const DEFAULT_NUMBER_ROWS_DISPLAY = 5;
const DEFAUT_NO_RESULTS_TEXT = "No Results"

type tableProps = {
					tableWidth: number;
					rowHeight: number;
					numberOfRowsToDisplay: number;
					fontSize: number,
					padding: number;
					margin: number;	
					tableHeightOffset: number;
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputLabel: {
			position: 'relative',
			background: '#fff',
			fontSize: '16px',
			paddingLeft: '0px',
			padding: '0px',
			zIndex: '9',
			color: 'black',
			fontWeight: '500'
		},
		tableContainer: (props: tableProps) => ({
			height: (props.rowHeight ?? DEFAULT_ROW_HEIGHT) * (props.numberOfRowsToDisplay ?? DEFAULT_NUMBER_ROWS_DISPLAY) + 1 + props.tableHeightOffset,
			overflowY: 'auto',
			width: props.tableWidth
		}),
		tableRow: (props: tableProps) => ({
			border: '1px solid black',
			height: props.rowHeight
		}),
		tableCell: (props: tableProps) => ({
			lineHeight: DEFAULT_LINE_HEIGHT,
			fontSize: props.fontSize,
			border: '1px solid black',
			padding: props.padding,
			margin: props.margin,
			color: '#838A95',
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: '500'
		}),
		emptyTableContainer: {
			width: "350px",
			overflowY: "hidden"
		},
		noRows: (props: tableProps) => ({
			fontSize: props.fontSize,
			color: '#838A95',
			fontFamily: 'Montserrat, sans-serif',
			fontWeight: '500'
		})
	})
);


const SecuritiesScrollableTable: React.FC<SecuritiesScrollableTableProps> = ({ id
																			,label
																			,data
																			,tableWidth = DEFAULT_TABLE_WIDTH
																			,numberOfRowsToDisplay = DEFAULT_NUMBER_ROWS_DISPLAY
																			,fontSize = DEFAULT_FONT_SIZE
																			,padding = DEFAULT_PADDING
																			,margin = DEFAULT_MARGIN
																			,noResultsText = DEFAUT_NO_RESULTS_TEXT
																			}) => {

	const cellRefs = useRef<(HTMLTableCellElement | null)[]>([]);
	const sortedData = [...data].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
	const [tableHeightOffset, setTableHeightOffset] = useState(0);

	const effectiveTableWidth = tableWidth < DEFAULT_TABLE_WIDTH ? DEFAULT_TABLE_WIDTH : tableWidth;

	const effectiveRowHeight = ((fontSize ?? DEFAULT_FONT_SIZE) * DEFAULT_LINE_HEIGHT)
							+ ((padding ?? DEFAULT_PADDING) * 2)
							+ ((margin ?? DEFAULT_MARGIN) * 2);


	const effectiveNumberOfRowsToDisplay = sortedData.length < numberOfRowsToDisplay ? sortedData.length > 0 ? sortedData.length : 1 : numberOfRowsToDisplay;

	const classes = useStyles({
					rowHeight: effectiveRowHeight,
					tableWidth: effectiveTableWidth,
					numberOfRowsToDisplay: effectiveNumberOfRowsToDisplay,
					fontSize,
					padding,
					margin,
					tableHeightOffset
	});

	useEffect(() => {

		const checkWrappedText = () => {
			let hasWrappedText = false;
			let offsetHeight = 0;
			let count = 0;

			cellRefs.current.forEach((cell, index) => {

				if (cell && count < effectiveNumberOfRowsToDisplay) {

					if (cell.offsetHeight > effectiveRowHeight) {
						hasWrappedText = true;
						offsetHeight += cell.offsetHeight - effectiveRowHeight;
					}

					count++;
				}
			});

			if (hasWrappedText) {
				setTableHeightOffset(offsetHeight);
			}
		};

		checkWrappedText();
	}, [effectiveNumberOfRowsToDisplay, effectiveRowHeight]);

	return (
			<>
			<InputLabel disabled>{label}</InputLabel>
			{!sortedData || sortedData.length === 0 ? (
				<TableContainer className={classes.emptyTableContainer}>
					<Table id={id} style={{ borderCollapse: 'collapse', width: '100%' }}>
						<TableBody>
							<TableRow>
								<TableCell colSpan={1}>
									<div className={classes.noRows} id="noResults">{ noResultsText }</div>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<TableContainer className={classes.tableContainer}>
					<Table id={id} style={{ borderCollapse: 'collapse', width: '100%' }}>
						<TableBody>
							{sortedData.map((item, index) => (
									<TableRow key={index} className={classes.tableRow}>
									<TableCell ref={(el: HTMLTableCellElement | null) => (cellRefs.current[index] = el)} className={classes.tableCell}>{item}</TableCell>
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			</>
	);
};

export default SecuritiesScrollableTable;