import React, { Fragment, useEffect, useState, useContext } from "react";
import { DEFAULT_ROWS_PER_PAGE, STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, Menu, MenuItem, Skeleton, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SecuritiesTable, {
	IColumnHeader,
	ISorter,
	ISecuritiesTableFieldMap,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import {
	getMailingSubmissionsAction,
	getSubmissionDocumentAction,
	abandonSubmissionAction
} from "../Submissions/submissions.actions";
import {
	ISubmissionListState,
	ISubmissionListItem,
	IFilterDTO,
	ICaseState,
} from "../../types/types";
import {
	BusinessAnalyst,
	EpiqProjectManager,
	EpiqTechSupport,
	Nominee
} from '../../constants/roles';

import { createFilterDTOsFromColumnHeaders } from "../../utilities/filterCriteria";
import { Link } from "react-router-dom";
import { getSubmissionStatusList, getSubmissionStatusText, transformNomineeStatusList } from '../../utilities/submissionStatus';
import { UserContext, UserContextType, } from "../../modules/User/UserContext";
import { SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE } from "../../constants/CommonConstants";
import { filterSubmissions } from "../Submissions/common";
import { signal, useSignal } from "@preact/signals-react";
import { SubmissionState, SubmissionType } from "../../types/enums";
import DialogReplaceMailing from "./DialogReplaceMailing";

const useStyles = makeStyles((theme: Theme) => ({
	greenColor: {
		color: "rgb(16, 189, 143)",
	},
	redColor: {
		color: "red",
	},
	grayColor: {
		color: "gray",
	},
	viewButton: {
		background: "#006B86",
		color: "white",
	},
	disabled: {
		display: 'none'
	},
	links: {
		color: "#006B86"
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const relatedSubmissionColumns: IColumnHeader[] = [
	{ field: "dateOfSubmission", label: "Submitted On" },
	{ field: "submissionDescription", label: "Submission #" },
	{ field: "fileName", label: "Document" },
	{ field: "submittedByUserName", label: "Submitted By" },
	{ field: "status", label: "State", hideSortIcon: true }
];

const defaultSort: ISorter = {
	property: "dateOfSubmission",
	order: "desc",
};

let sortInfo: ISorter = { ...defaultSort };
interface IMailingSubmissionGridProps {
	caseId?: number;
	slideoutDrawer: (id: number, flag: boolean) => void;
	slideoutDrawerRejections: (id: number, flag: boolean) => void;
	initialRowsPerPage?: number;
}

export default function MailingSubmissionGrid({ caseId, slideoutDrawer, slideoutDrawerRejections, initialRowsPerPage }: IMailingSubmissionGridProps) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isAuthenticated, isInitialized, isInRole, user } = useContext(UserContext) as UserContextType;

	const submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
	let initialFilter: IFilterDTO[];
	const submissionColumns = useSignal<IColumnHeader[]>([]);

	const selectedParentSubmissionId = useSignal<number | null>(null);
	const selectedSubmissionDescription = useSignal<string | null>(null);
	const replaceDialogOpen = useSignal<boolean | null>(null);
	const selectedCaseId = useSignal<number | null>(null);
	const selectedCaseNumber = useSignal<string | null>(null);
	const selectedCaseName = useSignal<string | null>(null);
	const onBehalfOfUserId = useSignal<number | null>(null);
	const onBehalfOfUserName = useSignal<string | null>(null);
	const selectedInstitutionId = useSignal<number | null>(null);
	const rowsPerPage = useSignal<number | undefined>(10);

	useEffect(() => {
		submissionColumns.value = getSubmissionColumns();
		populateStatusListFilter();
		initialFilter = createFilterDTOsFromColumnHeaders(submissionColumns.value);
		dispatch(getMailingSubmissionsAction({
			filter: initialFilter,
			caseId: caseId || 0,
			institutionId: user.institutionId,
			sorter: sortInfo,
			pageNumber: submissionListState.pageNumber || 1,
			rowsPerPage: rowsPerPage.value,
			isExternalUser: isInRole([Nominee])
		}));

	}, [dispatch, caseId]);

	useEffect(() => {
		if (submissionListState?.mailingSubmissionListNeedsRefresh) {
			populateStatusListFilter();
			initialFilter = createFilterDTOsFromColumnHeaders(submissionColumns.value);

			dispatch(getMailingSubmissionsAction({
				filter: initialFilter,
				caseId: caseId || 0,
				institutionId: user.institutionId,
				sorter: sortInfo,
				pageNumber: submissionListState.pageNumber || 1,
				rowsPerPage: rowsPerPage.value,
				isExternalUser: isInRole([Nominee])
			}));
		}

	}, [dispatch, submissionListState?.mailingSubmissionListNeedsRefresh]);

	useEffect(() => { rowsPerPage.value = initialRowsPerPage }, [initialRowsPerPage]);

	const onRowsPerServerPageChanged = (newRowsPerServerPage: number) => {
		rowsPerPage.value = newRowsPerServerPage;
	}

	const getSubmissionColumns = (): IColumnHeader[] => {
		const submissionColumns: IColumnHeader[] = [
			{
				field: "dateOfSubmission",
				label: "Submitted On",
				parameterName: "dateOfSubmissionEnd",
				type: "date",
				value: "",
				allowFilter: true,
			},
			{ field: "submissionDescription", label: "Submission #", parameterName: "number", type: "string", value: "", allowFilter: true },
			{ field: "fileName", label: "File Name", parameterName: "fileName", type: "string", value: "", allowFilter: true },
			{
				field: "institutionName",
				label: "Institution",
				parameterName: "institutionName",
				type: "string",
				value: "",
				allowFilter: true,
			},
			{
				field: "status",
				label: "State",
				parameterName: "statusList",
				type: "selectMany",
				enumValues: [],
				value: "",
				allowFilter: true,
				hideSortIcon: true
			},
			{ field: 'actions', label: 'Actions' }
		];
		// find submissionColumns entry where the submissionColumns.field === 'status'
		let statusColumn = submissionColumns.find(item => item.field === 'status');

		// if caseId is falsey, add caseName column

		if (!caseId) {
			// insert caseName column into submissionColumns at position 2
			submissionColumns.splice(2, 0, {
				field: "caseName",
				label: "Case Name",
				parameterName: "caseName",
				type: "string",
				value: "",
				allowFilter: true
			});
		}

		return submissionColumns;
	}

	const populateStatusListFilter = () => {
		// find submissionColumns entry where the submissionColumns.field === 'status'
		let statusColumn = submissionColumns.value.find(item => item.field === 'status');

		if (statusColumn?.enumValues?.length === 0) {
			statusColumn.enumValues = getSubmissionStatusList(isInRole([Nominee]));

			// remove Started, Partially Loaded, and Deficient from status list

			statusColumn.enumValues = statusColumn.enumValues?.filter(state =>
				state.label.toLowerCase() !== "started"
				&& state.label.toLowerCase() !== "partially loaded"
				&& state.label.toLowerCase() !== "deficient");
		}
	}

	const transformStatusListFilter = (filters: IFilterDTO[]) => {
		let statusListObj = filters.find(item => item.parameter === 'statusList');

		if (statusListObj)
			statusListObj.value = transformNomineeStatusList(statusListObj.value);

	}

	const fetchFilteredMailingSubmissionsList = (filters: IFilterDTO[], pageNumber?: number, rowsPerPage?: number) => {

		populateStatusListFilter();
		transformStatusListFilter(filters);

		dispatch(getMailingSubmissionsAction({
			filter: filters,
			caseId: caseId || 0,
			institutionId: user.institutionId,
			sorter: sortInfo,
			pageNumber: pageNumber,
			rowsPerPage: rowsPerPage,
			isExternalUser: isInRole([Nominee])
		}));
	};

	const getSubmissionDocument = (submissionId: number) => {
		dispatch(getSubmissionDocumentAction(submissionId));
	};

	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
	let [selectedRowId, setSelectedRowId] = useState(-1);

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>, elementId: number) => {
		setKabobsMenu(event.currentTarget);
		setSelectedRowId(elementId);
	};

	const handleClose = () => {
		setKabobsMenu(null);
		setSelectedRowId(-1);
	};

	const abandonSubmission = (id: number) => {
		dispatch(abandonSubmissionAction(id));
	};

	const buildRelatedSubmissionRows = (parentIndex: number, row: ISubmissionListItem): ISecuritiesTableFieldMap[] => {
		var relatedSubmissionRows: ISecuritiesTableFieldMap[] = [];

		if (!row.relatedSubmissions) {
			return relatedSubmissionRows;
		}

		row.relatedSubmissions.sort((a, b) => {
			if (a.dateOfSubmission > b.dateOfSubmission) {
				return -1;
			}

			if (a.dateOfSubmission < b.dateOfSubmission) {
				return 1;
			}

			return 0;
		});

		row.relatedSubmissions.forEach((item, index) => {
			if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
				item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

			let newItem: ISecuritiesTableFieldMap = {
				dateOfSubmission: { type: "date", value: item.dateOfSubmission },
				submissionDescription: {
					type: "string",
					value: item.submissionDescription
				},
				fileName: {
					type: "link",
					value: (
						<span style={{ cursor: "pointer", color: "#006B86" }}
							onClick={() => {
								getSubmissionDocument(item.id);
							}}
						>
							{item.fileName}
						</span>

					)
				},
				submittedByUserName: {
					type: "string",
					value: item.submittedByUserName
				},
				status: {
					type: "string",
					value: <span>{item.status}</span>,
					sortValue: item.status,
				}
			};

			relatedSubmissionRows.push(newItem);
		});

		return relatedSubmissionRows;
	};

	const userCanPerformSubmissionActions: boolean = (isInitialized && isAuthenticated && isInRole([EpiqProjectManager, EpiqTechSupport, BusinessAnalyst]));

	const getSubmissionActions = (caseId: number,
		submissionState: SubmissionState,
		submissionId: number,
		parentSubmissionId: number,
		caseName: string,
		caseNumber: string,
		submissionDescription: string,
		submittedByUserId: number,
		submittedByUsername: string,
		institutionId: number
	): any => {
		if (submissionState === SubmissionState.Rejected) {
			return (
				(
					<>
						<MenuItem
							onClick={() => {
								if (parentSubmissionId)
									selectedParentSubmissionId.value = parentSubmissionId;
								else
									selectedParentSubmissionId.value = submissionId;

								selectedSubmissionDescription.value = submissionDescription;
								selectedCaseId.value = caseId;
								selectedCaseNumber.value = caseNumber;
								selectedCaseName.value = caseName;
								onBehalfOfUserId.value = submittedByUserId;
								onBehalfOfUserName.value = submittedByUsername;
								selectedInstitutionId.value = institutionId;
								replaceDialogOpen.value = true;
							}}
						>
							<Tooltip title={
								<Typography style={{ fontSize: 14 }}>Replace Submission</Typography>
							}
								placement="left-end"
							>
								<Typography>Replace</Typography>
							</Tooltip>
						</MenuItem>

						<MenuItem
							onClick={() => {
								abandonSubmission(submissionId);

							}}
						>
							<Tooltip title={
								<Typography style={{ fontSize: 14 }}>Abandon Submission</Typography>
							}
								placement="left-end"
							>
								<Typography>Abandon</Typography>
							</Tooltip>
						</MenuItem>
					</>
				)
			);
		}
	};

	const buildSubmissionRows = (): ISecuritiesTableFieldMap[] => {
		var submissionRows: ISecuritiesTableFieldMap[] = [];

		let submissionsToDisplay: ISubmissionListItem[] = [];

		submissionsToDisplay = filterSubmissions(submissionListState.mailingSubmissions, isInRole([Nominee]))

		submissionsToDisplay &&
			submissionsToDisplay.forEach((item, index) => {
				let newItem: ISecuritiesTableFieldMap = {
					collapsible: { type: 'boolean', value: item.hasRelatedSubmissions ? true : false },
					subTableRows: { type: 'array', value: buildRelatedSubmissionRows(index, item) },
					dateOfSubmission: { type: "date", value: item.dateOfSubmission },
					submissionDescription: {
						type: "string",
						value: item.submissionDescription
					},
					caseName: {
						type: "link",
						value: (
							<Link color="success" to={`/CaseDetail/${item.caseId}`}>{item.caseName}</Link>
						),
						sortValue: item.caseName,
					},
					fileName: {
						type: "link",
						value: (
							<span style={{ cursor: "pointer", color: "#006B86" }}
								onClick={() => {
									getSubmissionDocument(item.latestSubmissionId);
								}}
							>
								{item.fileName}
							</span>

						),
						sortValue: item.fileName,
					},
					institutionName: {
						type: "link",
						value: (
							<Link color="success" to="#" onClick={() => { slideoutDrawer(item.institutionId, true) }}>{item.institutionName}</Link>
						),
						sortValue: item.institutionName,
					},
					status: {
						type: "link",
						value: <span>{item.status}</span>
					},
					actions: {
						type: "link",
						value:
							<Fragment>
								<div>

									<IconButton
										aria-label="more"
										className={classes.kabobIconEnable}
										id={"long-button-" + index}
										aria-haspopup="true"
										sx={{ padding: "0" }}
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											handleClick(event, index);
										}}
										disabled={[
											SubmissionState.Started,
											SubmissionState.Submitted,
											SubmissionState.Extracting,
											SubmissionState.Extracted,
											SubmissionState.Verifying,
											SubmissionState.Verified,
											SubmissionState.Loading,
											SubmissionState.Loaded,
											SubmissionState.Abandoned,
											SubmissionState.Error,
											SubmissionState.PartiallyExtracted,
											SubmissionState.Received].includes(item.submissionStateId)
											|| !userCanPerformSubmissionActions}
									>
										<MoreVertIcon />
									</IconButton>

									<Menu
										id={"long-menu-" + index}
										MenuListProps={{
											"aria-labelledby": "long-button" + index,
										}}
										anchorEl={kabobsMenu}
										open={selectedRowId === index}
										onClose={handleClose}
										onClick={handleClose}
										PaperProps={{
											style: {
												maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
												width: "20ch",
											},
										}}

									>
										{userCanPerformSubmissionActions
											&& getSubmissionActions(item.caseId,
												item.submissionStateId,
												item.latestSubmissionId,
												item.parentSubmissionId,
												item.caseName,
												item.caseNumber,
												item.submissionDescription,
												item.submittedByUserId,
												item.submittedByUserName,
												item.institutionId
											)
										}
									</Menu>


								</div>
							</Fragment>
					}
				};

				submissionRows.push(newItem);
			});

		return submissionRows;
	};

	const rows = buildSubmissionRows();

	return (
		<>
			{
				(submissionListState.isLoaded) ?
					(<SecuritiesTable
						columns={submissionColumns.value}
						subTableColumns={relatedSubmissionColumns}
						subTableTitle={'History'}
						rows={rows}
						totalRowCount={submissionListState.totalRowCount}
						sorter={sortInfo}
						paging={'server'}
						pageNumber={submissionListState.pageNumber - 1}  // page numbers need to be 0-based to keep mui happy
						rowsPerServerPage={rowsPerPage.value}
						rowsPerServerPageChanged={onRowsPerServerPageChanged}
						showFilter={true}
						dataFetchFunc={fetchFilteredMailingSubmissionsList}
						collapsible={true}
						resetFilterFunc={handleResetFilter}
					/>
					)
					:
					(<Card>
						<CardContent>
							{[...Array(5)].map((elementInArray, index) => (
								<Grid container spacing={2} key={index}>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
								</Grid>
							))}
						</CardContent>
					</Card>)
			}

			<DialogReplaceMailing
				ParentSubmissionId={selectedParentSubmissionId.value}
				SubmissionDescription={selectedSubmissionDescription.value}
				Open={replaceDialogOpen.value}
				CaseId={selectedCaseId.value}
				CaseNumber={selectedCaseNumber.value}
				CaseName={selectedCaseName.value}
				OnBehalfOfUserId={onBehalfOfUserId.value}
				OnBehalfOfUserName={onBehalfOfUserName.value}
				InstitutionId={selectedInstitutionId.value}
				OnClose={() => { replaceDialogOpen.value = false; }}
			/>
		</>
	);
}