import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangeEvent, useContext, useState, useEffect, useRef } from 'react';
import { Backdrop, Box, CircularProgress, LinearProgress, Stack, Theme, Typography } from '@mui/material';
import { SubmissionType, Strings } from '../../types/enums';
import { IStringValue } from '../../types/types';
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import authService from '../../components/api-authorization/AuthorizeService';
import axios from 'axios';
import { signal, useSignal } from '@preact/signals-react';
import { publishSubmissionMessageAsync } from '../ClaimFileUpload/claimFile.api';
import DialogGetApprovedNominees from './DialogGetApprovedNominees';
import SearchIcon from '@mui/icons-material/Search';
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import makeStyles from '@mui/styles/makeStyles';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { EpiqUser } from "../../constants/roles";
import { mailingFileMaxSizeMb } from "../../App";
import { useDispatch } from "react-redux";
import { mailingSubmissionsListNeedsRefreshAction } from "../Submissions/submissions.actions";

export interface DialogReplaceMailingProps {
	ParentSubmissionId: number | null;
	SubmissionDescription: string | null;
	Open: boolean | null;
	CaseId: number | null;
	CaseNumber: string | null;
	CaseName: string | null;
	OnBehalfOfUserId: number | null;
	OnBehalfOfUserName: string | null;
	InstitutionId: number | null;
	OnClose: Function;
}

const useStyles = makeStyles((theme: Theme) => ({

	disabledButton: {
		backgroundColor: '#cfcfcf !important',
	},
	primaryBtn: {
		color: '#fff!important',
		backgroundColor: '#007bff!important'
	},
	secondaryBtn: {
		color: 'blue',
		backgroundColor: '#fff!important',
		border: '1px solid blue',
	},
	floatLeft: {
		textAlign: 'left',
		marginLeft: '10px',
		float: 'left',
	},
	infoIcon: {
		fontSize: "20px"
	},
}));

export default function DialogReplaceMailing(dialogProperties: DialogReplaceMailingProps) {
	const { isInRole } = useContext(UserContext) as UserContextType;
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const [formData, setFormData] = useState<FormData | null>(null);
	const [error, setError] = useState<TypeError | undefined>(undefined as TypeError | undefined);
	const { user } = useContext(UserContext) as UserContextType;
	const [addMailingHelpStringContent, setAddMailingHelpStringContent] = useState<IStringValue | null>();
	const [mailingFileSizeStringContent, setMailingFileSizeStringContent] = useState<IStringValue | null>();
	const [mailingFileSizeExceededStringContent, setMailingFileSizeExceededStringContent] = useState<IStringValue | null>();
	const classes = useStyles();
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	let [showMailingHelp, setShowMailingHelp] = useState(false);
	const dispatch = useDispatch();

	const uploading = useSignal(false);
	const progress = useSignal(0);

	const handleInfoClick = (event: any) => {
		setShowMailingHelp(true);
		setTarget(event.target);
	}

	const fetchInfoStrings = async () => {
		const data: any = await getInfoJsonWithArray([
			Strings.AddMailingHelp,
			Strings.MailingFileSize,
			Strings.MailingFileSizeExceeded,
		]);

		processInfoResponse(data);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.AddMailingHelp:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setAddMailingHelpStringContent(item);
					break;

				case Strings.MailingFileSize:
					setMailingFileSizeStringContent(item);
					break;

				case Strings.MailingFileSizeExceeded:
					setMailingFileSizeExceededStringContent(item);
					break;

				default:
					break;
			}
		});
	};

	useEffect(() => {
		setOpen(dialogProperties.Open ?? false);

		if (!dialogProperties.Open) return;

		setSuccess(false);
	}, [dialogProperties.Open]);

	useEffect(() => {
		fetchInfoStrings();
	}, []);

	const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
		// keep users from being able to close the dialog by clicking the backdrop
		if (reason === 'backdropClick') {
			return
		}

		uploading.value = false;
		setFormData(null);
		setFile(null);
		setSuccess(false);
		setError(undefined);
		setOpen(false);
		dialogProperties.OnClose();
	};

	const getFileSizeMb = (file: any) => {
		const bytesInMb = 1048576;

		let sizeMb = file.size / bytesInMb;
		return sizeMb;
	}

	function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
		setFormData(null);
		setFile(null);
		setSuccess(false);
		setError(undefined);

		const file = event.target.files?.[0];

		if (!file) {
			setFile(null);
			setFormData(null);
			return;
		}

		const fileSize = getFileSizeMb(file);

		if (fileSize > mailingFileMaxSizeMb.value) {
			setError(new Error(mailingFileSizeExceededStringContent?.value));
			setFile(null);
			setFormData(null);
			return;
		}

		const formData = new FormData();
		formData.append('claimFile', file);
		formData.append('caseId', dialogProperties.CaseId?.toString() ?? '');

		if (dialogProperties.OnBehalfOfUserId) {
			formData.append('userId', dialogProperties.OnBehalfOfUserId.toString());
			formData.append('submittedByEpiqUserId', user.id.toString());
			formData.append('institutionId', dialogProperties.InstitutionId?.toString() ?? '0');
		} else {
			formData.append('userId', user.id.toString());
			formData.append('institutionId', user.institutionId.toString());
		}

		formData.append('submissionType', SubmissionType.MailingFile.toString());
		formData.append('parentSubmissionId', dialogProperties.ParentSubmissionId?.toString() ?? '0');
		formData.append('statedClaimantCount', "0");
		formData.append('statedTransactionCount', "0");

		setFormData(formData);
		setFile(file.name);

	}

	const handleContinue = async () => {
		uploading.value = true;

		let token = await authService.getAccessToken();

		axios.post('api/Intake/UploadClaimFileAsync', formData, {
			headers: {
				'Authorization': `Bearer ${token}`
			},
			onUploadProgress: (progressEvent) => {
				uploading.value = true;
				progress.value = Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 0));
			}
		})
			.then(response => {
				console.log('File uploaded successfully:', response.data);
				publishSubmissionMessageAsync(response.data.submissionId);
				setSuccess(true);
				uploading.value = false;

				dispatch(mailingSubmissionsListNeedsRefreshAction());
			})
			.catch(error => {
				console.error('Error uploading file:', error);
				setError(error);
				uploading.value = false;
			});
	}

	function handleHelp() {
		alert(addMailingHelpStringContent?.value);
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: 9999999 }}
				open={uploading.value}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CircularProgress color="inherit" />

					<Typography variant="h6" sx={{ mt: 2 }}>
						Uploading and preprocessing file...
					</Typography>
				</Box>
			</Backdrop>

			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
					<Typography sx={{ 'color': "#000D13 !important", 'marginBottom': "0" }} variant="h6" noWrap>
						Replace Mailing
					</Typography>

					<div className={classes.floatLeft} ref={ref}>
						<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }}
							onClick={handleInfoClick}><InfoOutlinedIcon className={classes.infoIcon} />
						</Button>

						<Overlay
							show={showMailingHelp}
							target={target}
							placement="right-start"
							container={ref}
							containerPadding={20}
							rootClose
							onHide={() => setShowMailingHelp(false)}
						>
							<Popover id="popover-mailingHelp">
								<Popover.Header as="h3"><span style={{ color: "#000" }}>Mailings</span></Popover.Header>
								{addMailingHelpStringContent && (
									<Popover.Body dangerouslySetInnerHTML={{ __html: addMailingHelpStringContent.value }}></Popover.Body>
								)}
							</Popover>
						</Overlay>
					</div>
				</DialogTitle>

				<DialogContent>
					<DialogContentText style={{ color: "inherit" }}>
						<b>Case:</b> {dialogProperties.CaseNumber}, {dialogProperties.CaseName}
						<br />
						<b>Submission:</b> {dialogProperties.SubmissionDescription}
					</DialogContentText>

					{!success &&
						<DialogContentText style={{ marginTop: "8px", marginBottom: "8px", fontWeight: "bold", color: "inherit" }}>Replace Mailing List</DialogContentText>
					}

					{!success &&
						<div>
							<div style={{ float: 'left', lineHeight: '2.8rem', textAlign: 'center', paddingLeft: '14px', marginRight: '1rem' }}>
								On behalf of
							</div>
							<div style={{ float: 'left' }}>
								<TextField
									style={{
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '300px',
									}}
									size="small"
									value={dialogProperties.OnBehalfOfUserName}
								/>
							</div>
						</div>
					}

					{success &&
						<>
							<DialogContentText style={{ marginTop: "8px", marginBottom: "8px", fontWeight: "bold", color: "inherit" }}>Thanks!  You have successfully replaced a mailing list!</DialogContentText>
							<DialogContentText style={{ marginTop: "8px", color: "inherit" }}><b>Mailing List:</b> {file}</DialogContentText>
						</>
					}

					{!success &&
						<Stack direction="column" style={{ clear: 'left' }}>
							<TextField
								error={error && error !== null}
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								multiline
								helperText={error && error.message}
								label={error && "Error"}
								value={file && file !== undefined
									? file
									: 'No file selected...'} >
							</TextField>

							<DialogContentText style={{ color: "inherit" }}>
								{mailingFileSizeStringContent?.value || ''}
							</DialogContentText>

							<Button
								style={{
									color: '#fff',
									backgroundColor: '#007bff',
									width: "128px",
									marginTop: "8px"
								}}
								component="label"
								color="primary"
								variant="contained"
								disabled={uploading.value}
							>
								Select a file
								<input
									id="claimFileInput"
									name="claimFileInput"
									type="file"
									accept=".xlsx"
									hidden
									onChange={handleFileChange} />
							</Button>
						</Stack>
					}

					{uploading.value &&
						<Box sx={{ width: '100%', mt: 2 }}>
							<LinearProgress variant="determinate" value={progress.value} />
						</Box>
					}
				</DialogContent>

				<DialogActions>
					{!success &&
						<Button
							classes={!file || uploading.value ? { root: classes.disabledButton, disabled: classes.disabledButton } :
								{ root: classes.primaryBtn, disabled: classes.primaryBtn }}
							onClick={handleContinue} color="primary"
							disabled={!file || uploading.value}
							variant='outlined'>
							Continue
						</Button>
					}

					{!success &&
						<Button
							classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
							color="secondary"
							onClick={handleClose as React.MouseEventHandler<HTMLButtonElement>}
							variant="outlined" disabled={uploading.value}>
							Cancel
						</Button>
					}

					{success && (
						<Button
							onClick={handleClose as React.MouseEventHandler<HTMLButtonElement>}
							classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
							color="secondary" variant='contained'
						>
							Close
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}
