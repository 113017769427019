import { Theme, Backdrop, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useContext, useState, useRef } from "react";
import { useSignal } from '@preact/signals-react';
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Button } from "@mui/material";
import SubmissionListGrid from "./SubmissionListGrid";
import { useHistory, useParams } from 'react-router-dom';
import { EpiqUser, Nominee } from '../../constants/roles';
import {
	SubmissionType,
	SubmissionTypeDisplay,
	SubmissionState,
	SubmissionTabTitle,
	Strings
} from "../../types/enums";
import {
	IClaimFileModalProps,
	ISupportingDocModalProps,
	ISubmissionListState,
	IRegisterUser,
	IStringValue,
	ISubmissionRejectionSlideoutProps
} from '../../types/types';
import ClaimFileUploadModal from '../ClaimFileUpload/ClaimFileUploadModal';
import SupportingDocFilesUploadModal from '../ClaimFileUpload/SupportingDocFilesUploadModal';
import DeficiencyClaimFileUploadModal from '../ClaimFileUpload/DeficiencyClaimFileUploadModal';
import DeficiencyOptionalFilesUploadModal from '../ClaimFileUpload/DeficiencyOptionalFilesUploadModal';
import { getEnumKeyByEnumValue, getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import InstitutionSlideoutDrawer from "./InstitutionSlideoutDrawer";
import RejectionsSlideoutDrawer from "./RejectionsSlideoutDrawer";
import UserSlideoutDrawer from "./UserSlideoutDrawer";
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import { getInstitutionDetailsByIdAction} from "./submissions.actions";
import { getUserDetailsByIdAction } from "./submissions.actions";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import authService from '../../components/api-authorization/AuthorizeService';
import ConfirmationMessageModal from "../../components/Shared/ConfirmationMessageModal";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
	fab: {
		position: "absolute",
		bottom: theme.spacing(5),
		right: theme.spacing(5),
	},
	button: {
		background: "#006B86",
		size: "small",
		margin: theme.spacing(1),
		color: "white",
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: "0.5em",
	},
	commonbackgroundcard: {
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		padding: '1.5rem 1rem 0.25rem',
		margin: "1rem 1rem 0 0",
		background: "#fff",
		borderRadius: "5px",
		"& tbody tr": {
			borderBottom: "1px solid rgba(224, 224, 224, 1)"
		},
		"& thead tr th:first-child": {
			width: "11%"
		},
		"& thead tr th:nth-child(2)": {
			width: "12%"
		},
		"& thead tr th:nth-child(3)": {
			width: "12%"
		},
		"& thead tr th:nth-child(3) > div": {
			width: "100%"
		},
		"& thead tr th:nth-child(4)": {
			width: "17%"
		},
		"& thead tr th:nth-child(4) > div": {
			width: "100%"
		},
		"& thead tr th:nth-child(5)": {
			width: "12%"
		},
		"& thead tr th:nth-child(6)": {
			width: "12%"
		},
		"& thead tr th:nth-child(7)": {
			width: "8%"
		},
		"& thead tr th:nth-child(7) span svg": {
			display: "none"
		},
		"& thead tr th:nth-child(7) p": {
			display: "none"
		},
		"& thead tr th:last-child": {
			width: "16%"
		},
		"& thead tr th:last-child div": {
			minWidth: "100%"
		},
		"& tbody tr td": {
			borderBottom: "0px !important"
		},
		"& tbody tr td:first-child": {
			width: "11%",
			paddingLeft: "20px !important"
		},
		"& tbody tr td:nth-child(2)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(3)": {
			width: "17%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(4)": {
			width: "17%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(5)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(6)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(7)": {
			width: "8%",
			textAlign: "left"
		},
		"& tbody tr td:last-child": {
			width: "8%",
			borderBottom:"none"
		},
		"& tbody tr td:last-child li": {
			float: "left",
			padding: "0"
		},
		"@media (max-width: 1368px)": {
			"& thead tr th span": {
				fontSize: "0.8rem"
			},
			"& tbody tr td": {
				fontSize: "0.8rem"
			},
			"& tbody tr td a": {
				fontSize: "0.8rem"
			},
			"& thead tr th:first-child": {
				width: "13%"
			},
			"& thead tr th:nth-child(2)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(3)": {

				width: "14.5%"
			},
			"& thead tr th:nth-child(4)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(5)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(6)": {
				width: "12.5%"
			},
			"& thead tr th:last-child": {
				width: "16.5%"
			},
			"& thead tr th:last-child div": {
				minWidth: "100%"
			},
			"& tbody tr td:first-child": {
				width: "13%"
			},
			"& tbody tr td:nth-child(2)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(3)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(4)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(5)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(6)": {
				width: "12.5%",
				textAlign: "left"
			},
			"& tbody tr td:last-child": {
				width: "16.5%"
			},
		},
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: 'inline-flex',
		marginRight: "5px"
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

const SubmissionListPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isAuthenticated, user, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	const history = useHistory();
	let [claimFileModalIsOpen, setClaimFileModalIsOpen] = useState(false);
	let [supportingDocFilesModalIsOpen, setSupportingDocFilesModalIsOpen] = useState(false);
	let [claimFileModalProps, setClaimFileModalProps] = useState<IClaimFileModalProps>();
	let [supportingDocModalProps, setSupportingDocModalProps] = useState<ISupportingDocModalProps>()
	let [isDirty, setIsDirty] = useState(false);
	let submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
	let [deficiencyClaimFileModalIsOpen, setDeficiencyClaimFileModalIsOpen] = useState(false);
	let [deficiencyOptionalFilesModalIsOpen, setDeficiencyOptionalFilesModalIsOpen] = useState(false);

	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();

	const location = useLocation();
	document.title = SubmissionTabTitle.Title;
	const slideoutRejectionsDrawerOpen = useSignal(false);
	const [submissionRejectionSlideoutProps, setSubmissionRejectionSlideoutProps] = useState<ISubmissionRejectionSlideoutProps>();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: SubmissionTabTitle.Title });
	}, [location]);

	let [selectedUser, setSelectedUser] = useState<IRegisterUser>();

	const isDownloading: boolean = useSelector((state: any) => {
		return state.submissionListState.isDownloading;
	});

	const selectSubmission = (caseId: number,
		submissionTypeDisplay: SubmissionTypeDisplay,
		submissionId: number,
		caseName: string,
		caseNumber: string
	) => {

		setIsDirty(false);
		setSupportingDocFilesModalIsOpen(false);

		setClaimFileModalProps(({
			handleClose: handleUploadClaimFileModalClosed,
			submissionTypeDisplay: submissionTypeDisplay,
			submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
			parentSubmissionId: submissionId,
			caseId: caseId,
			caseName: caseName,
			caseNumber: caseNumber,
			isNonClaimDeficiencyResponse: false,
			handleSupportingDocModalOpen: handleSupportingDocModalOpen,
			handleSupportingDocModalClose: handleSupportingDocModalClose,
			setSupportingDocModalProps: setSupportingDocModalProps
		}));

		setClaimFileModalIsOpen(true);
	};

	const resumeSubmission = (caseId: number,
		submissionType: SubmissionType,
		submissionId: number,
		caseName: string,
		caseNumber: string,
		submissionState: SubmissionState,
		submissionDescription: string
	) => {

		setIsDirty(false);
		var submissionTypeDisplay: SubmissionTypeDisplay = SubmissionTypeDisplay[SubmissionType[submissionType] as unknown as keyof typeof SubmissionTypeDisplay];

		setSupportingDocModalProps(
									{
										handleClose: submissionType === SubmissionType.Deficiency ?
																			 handleDeficiencyOptionalFilesModalClose
																			:handleSupportingDocModalClose,
										submissionTypeDisplay: submissionTypeDisplay,
										submissionType: submissionType,
										submissionId: submissionId,
										caseId: caseId,
										caseName: caseName,
										caseNumber: caseNumber,
										submissionState: submissionState,
										submissionDescription: submissionDescription
									}
								);

		if(submissionType === SubmissionType.Deficiency)
		{
			setDeficiencyOptionalFilesModalIsOpen(false);
			handleDeficiencyOptionalFilesModalOpen();
		}
		else
		{
			setSupportingDocFilesModalIsOpen(false);
			handleSupportingDocModalOpen();
		}

	};

	const handleUploadClaimFileModalClosed = () => {
		setClaimFileModalIsOpen(false);
	};

	const handleSupportingDocModalOpen = () => {
		setSupportingDocFilesModalIsOpen(true);
	};

	const handleSupportingDocModalClose = (submitted: boolean = false) => {
		if (submitted) {
			//show confirmation modal
			setConfirmationModalOpen(true);
		}
		setSupportingDocFilesModalIsOpen(false);
		setIsDirty(true);
	};

	const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

	const handleClose = (
		event: {},
		reason: "backdropClick" | "escapeKeyDown"
	) => {
		if (reason === "backdropClick") {
			console.log(reason);
		} else {
			setConfirmationModalOpen(false);
		}
	};

	let { type } = useParams<{ type: string }>();
	let { isUser } = useParams<{ isUser: string }>();

	const [slideoutDrawerOpen, setslideoutDrawerOpen] = React.useState(false);

	const slideoutDrawerInstitution = (id: number) => {
		dispatch(getInstitutionDetailsByIdAction(id));
		setslideoutDrawerOpen(true);
	};

	const handleRejectionsDrawerClose = () => {
		slideoutRejectionsDrawerOpen.value = false;
	};

	const slideoutDrawerRejections = (id: number) => {

		var submission = submissionListState.submissions
			.find(r => r.id === id)

		slideoutRejectionsDrawerOpen.value = true;
		setSubmissionRejectionSlideoutProps(
			{
				submissionId: id,
				submissionFileName:	(submission) ?submission.fileName : "",
				handleClose: handleRejectionsDrawerClose
			}
		);
	};

	const slideoutDrawerUser = (id: number) => {
		dispatch(getUserDetailsByIdAction(id));
		setslideoutDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setslideoutDrawerOpen(false);
	};

	useEffect(() => {
		if (type === ':type' || isUser === ':isUser') {
			history.replace('/SubmissionList/');
		}

	}, [isAuthenticated]);

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.SubmissionList:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;


				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.SubmissionList,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isAuthenticated, isInitialized]);

	const selectDeficiencySubmission = (caseId: number,
		submissionTypeDisplay: SubmissionTypeDisplay,
		submissionId: number,
		caseName: string,
		caseNumber: string) => {
		setSupportingDocFilesModalIsOpen(false);

		setClaimFileModalProps(({
			handleClose: handleDeficiencyUploadClaimFileModalClosed,
			submissionTypeDisplay: submissionTypeDisplay,
			submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
			parentSubmissionId: submissionId,
			caseId: caseId,
			caseName: caseName,
			caseNumber: caseNumber,
			handleSupportingDocModalOpen: handleDeficiencyOptionalFilesModalOpen,
			handleSupportingDocModalClose: handleDeficiencyOptionalFilesModalClose,
			setSupportingDocModalProps: setSupportingDocModalProps,
			isNonClaimDeficiencyResponse:false
		}));

		setDeficiencyClaimFileModalIsOpen(true);
	};

	const handleDeficiencyUploadClaimFileModalClosed = () => {
		setDeficiencyClaimFileModalIsOpen(false);
	};

	const handleDeficiencyOptionalFilesModalOpen = () => {
		setDeficiencyOptionalFilesModalIsOpen(true);
	};

	const handleDeficiencyOptionalFilesModalClose = () => {
		setDeficiencyOptionalFilesModalIsOpen(false);
	};



	return (
		<div className={classes.commonbackgroundcard} ref={ref}>
			<ConfirmationMessageModal open={confirmationModalOpen} handleClose={handleClose} />

			<Typography variant="h6" className={classes.title} noWrap>
				Claim Submissions
			</Typography>

			<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>

			<Overlay
				show={show}
				target={target}
				placement="right-start"
				container={ref}
				containerPadding={20}
				rootClose
				onHide={() => setShow(false)}
			>
				<Popover id="popover-contained">
					<Popover.Header as="h3"><span style={{ color: "#000" }}>Claim Submissions</span></Popover.Header>
					{stringContentData && (
						<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
					)}
				</Popover>
			</Overlay>

			<SubmissionListGrid
				slideoutDrawer={slideoutDrawerInstitution}
				selectSubmission={selectSubmission}
				resumeSubmission={resumeSubmission}
				isDirty={isDirty}
				selectDeficiencySubmission={selectDeficiencySubmission}
				slideoutDrawerRejections={slideoutDrawerRejections}
			/>

			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDownloading}>
				<Stack alignItems="center">
					<CircularProgress color="inherit" />
					<br />
					<Typography variant="h4" color="InfoText">
						Downloading document...
					</Typography>
				</Stack>
			</Backdrop>

			{claimFileModalIsOpen && claimFileModalProps &&
				<ClaimFileUploadModal
					modalOpen={claimFileModalIsOpen}
					claimFileModalProps={claimFileModalProps}
				/>
			}

			{supportingDocFilesModalIsOpen && supportingDocModalProps &&
				<SupportingDocFilesUploadModal
					modalOpen={supportingDocFilesModalIsOpen}
					supportingDocModalProps={supportingDocModalProps}
				/>
			}

			{
				deficiencyClaimFileModalIsOpen && claimFileModalProps &&
				<DeficiencyClaimFileUploadModal
					modalOpen={deficiencyClaimFileModalIsOpen}
					claimFileModalProps={claimFileModalProps}
				/>
			}

			{
				deficiencyOptionalFilesModalIsOpen && supportingDocModalProps &&
				<DeficiencyOptionalFilesUploadModal
					modalOpen={deficiencyOptionalFilesModalIsOpen}
					supportingDocModalProps={supportingDocModalProps}
				/>
			}

			{/* InstitutionSlideoutDrawer */}
			{
				submissionListState?.isLoading === false && submissionListState?.institutionDetail &&
				(
					<>
						{
							<InstitutionSlideoutDrawer
								slideoutDrawerInstitution={submissionListState}
								isOpen={slideoutDrawerOpen}
								handleClose={handleDrawerClose}
							/>
						}
					</>
				)
			}

			{/* RejectionsSlideoutDrawer */}
			{
				slideoutRejectionsDrawerOpen && submissionRejectionSlideoutProps && 
				(
					<>
						{
							<RejectionsSlideoutDrawer
								isOpen={slideoutRejectionsDrawerOpen.value}
								submissionRejectionSlideoutProps={submissionRejectionSlideoutProps}
							/>
						}
					</>
				)
			}

			<UserSlideoutDrawer
				selectedDrawerUser={selectedUser}
				isOpen={slideoutDrawerOpen}
				handleClose={handleDrawerClose}
			/>

			{submissionListState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
		</div>
	);
};

export default SubmissionListPage;
