import {
    ISubmissionListState,
    IAction
} from '../../types/types';
import {
    CLEAR_SUBMISSION_DOCUMENTS_LIST,
    DOWNLOAD_SUBMISSION,
    DOWNLOAD_SUBMISSION_FAILED,
    DOWNLOAD_SUBMISSION_SUCCESS,
    GET_CASE_SUBMISSIONS,
    GET_CASE_SUBMISSIONS_FAILED,
    GET_CASE_SUBMISSIONS_SUCCESS,
    CLEAR_CASE_SUBMISSIONS_LIST,
    GET_MAILING_SUBMISSIONS,
    GET_MAILING_SUBMISSIONS_FAILED,
    GET_MAILING_SUBMISSIONS_SUCCESS,
    CLEAR_MAILING_SUBMISSIONS_LIST,
    MAILING_SUBMISSIONS_LIST_NEEDS_REFRESH,
    ABANDON_SUBMISSION_SUCCESS,
    ABANDON_SUBMISSION_FAILED,
    GET_INSTITUTION_DETAILS_BY_ID,
    GET_INSTITUTION_DETAILS_BY_ID_FAILURE,
    GET_INSTITUTION_DETAILS_BY_ID_SUCCESS,
    GET_RELATED_SUBMISSIONS,
    GET_RELATED_SUBMISSIONS_FAILED,
    GET_RELATED_SUBMISSIONS_SUCCESS,
    GET_SUBMISSION_DETAIL_BY_ID,
    GET_SUBMISSION_DETAIL_BY_ID_FAILED,
    GET_SUBMISSION_DETAIL_BY_ID_SUCCESS,
    GET_SUBMISSION_DOCUMENTS_LIST,
    GET_SUBMISSION_DOCUMENTS_LIST_FAILED,
    GET_SUBMISSION_DOCUMENTS_LIST_SUCCESS,
    GET_USER_DETAILS_BY_ID,
    GET_USER_DETAILS_BY_ID_FAILURE,
    GET_USER_DETAILS_BY_ID_SUCCESS,
    GET_DOCUMENT,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAILED,
    GET_DEFICIENCY_COUNT,
    GET_DEFICIENCY_COUNT_SUCCESS,
    GET_DEFICIENCY_COUNT_FAILED,
    GET_GENERATE_DEFICIENT_TEMPLATE,
    GET_GENERATE_DEFICIENT_TEMPLATE_SUCCESS,
    GET_GENERATE_DEFICIENT_TEMPLATE_FAILED,
    SET_SHOW_USER_SUBMISSIONS,
    BATCH_APPROVAL_OPTIONS_BY_ID,
    BATCH_APPROVAL_OPTIONS_BY_ID_FAILED,
    BATCH_APPROVAL_OPTIONS_BY_ID_SUCCESS,
} from './submissions.actions';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/CommonConstants';

const initialState: ISubmissionListState = {
    isLoading: false,
    isLoaded: false,
    isDownloading: false,
    submissions: [],
    mailingSubmissions: [],
    totalRowCount: 0,
    pageNumber: 1,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    error: null,
    errorGetRelatedSubmissions: null,
    errorGetDocuments: null,
    errorGetSubmissionDocument: null,
    institutionDetail: {
        id: 0, address1: '', address2: '', address3: '', city: '', country: '', emailAddress: '',
        insertedByUserId: 0, insertedByUserName: '', insertedOn: new Date(), institutionTypeName: '',
        name: '', normalizedAddress: '', phone: '', stateProvince: '', institutionTypeId: 0,
        updatedByUserId: 0, updatedOn: new Date(), zipCode: ''
    },
    submissionDocuments: [],
    documentsLoading: false,
    documentsLoaded: false,
    relatedSubmissions: [],
    showUserSubmissions: true,
    deficientTemplateGenerating: false,
    batchApprovalOptionsResponse: false,
    isBatchApprovalProcessLoading: false,
    mailingSubmissionListNeedsRefresh: false
}

const handleAction = (state = initialState, { type, payload }: IAction) => {
    switch (type) {
        case GET_CASE_SUBMISSIONS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                submissions: [],
                pageNumber: payload.pageNumber,
                rowsPerPage: payload.rowsPerPage
            }
        case GET_CASE_SUBMISSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                submissions: payload.submissions,
                totalRowCount: payload.totalRowCount
            }
        case GET_CASE_SUBMISSIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            }
        case CLEAR_CASE_SUBMISSIONS_LIST:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                totalRowCount: null,
                submissions: []
            }
        case GET_MAILING_SUBMISSIONS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                mailingSubmissions: [],
                pageNumber: payload.pageNumber,
                rowsPerPage: payload.rowsPerPage,
                mailingSubmissionListNeedsRefresh: false
            }
        case GET_MAILING_SUBMISSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                mailingSubmissions: payload.submissions,
                totalRowCount: payload.totalRowCount,
                mailingSubmissionListNeedsRefresh: false
            }
        case GET_MAILING_SUBMISSIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload,
                mailingSubmissionListNeedsRefresh: false
            }
        case CLEAR_MAILING_SUBMISSIONS_LIST:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                totalRowCount: null,
                mailingSubmissions: [],
                mailingSubmissionListNeedsRefresh: false
            }
        case MAILING_SUBMISSIONS_LIST_NEEDS_REFRESH:
            return {
                ...state,
                mailingSubmissionListNeedsRefresh: true
            }
        case ABANDON_SUBMISSION_SUCCESS:
            return {
                ...state,
                mailingSubmissionListNeedsRefresh: true
            }
        case ABANDON_SUBMISSION_FAILED:
            return {
                ...state,
                mailingSubmissionListNeedsRefresh: false
            }
        case DOWNLOAD_SUBMISSION:
            return {
                ...state,
                isDownloading: true,
                errorGetSubmissionDocument: null
            }
        case DOWNLOAD_SUBMISSION_SUCCESS:
            return {
                ...state,
                isDownloading: false
            }
        case DOWNLOAD_SUBMISSION_FAILED:
            return {
                ...state,
                isDownloading: false,
                errorGetSubmissionDocument: payload
            }
        case GET_SUBMISSION_DETAIL_BY_ID:
            return {
                ...state,
                isLoading: true,
                submissionDetail: null,
                error: null,
                errorGetRelatedSubmissions: null,
                errorGetDocuments: null
            }
        case GET_SUBMISSION_DETAIL_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                submissionDetail: payload
            }
        case GET_SUBMISSION_DETAIL_BY_ID_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_INSTITUTION_DETAILS_BY_ID:
            return {
                ...state,
                isLoading: true,
                institutionDetail: null
            }
        case GET_INSTITUTION_DETAILS_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                institutionDetail: payload,
            }
        case GET_INSTITUTION_DETAILS_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_USER_DETAILS_BY_ID:
            return {
                ...state,
                isLoading: true,
                userSlideoutDetail: null
            }
        case GET_USER_DETAILS_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userSlideoutDetail: payload,
            }
        case GET_USER_DETAILS_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_SUBMISSION_DOCUMENTS_LIST:
            return {
                ...state,
                documentsLoaded: false,
                documentsLoading: true,
                submissionDocuments: [],
                deficiencyCount: null,
            }
        case GET_SUBMISSION_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                documentsLoaded: true,
                documentsLoading: false,
                submissionDocuments: payload
            }
        case GET_SUBMISSION_DOCUMENTS_LIST_FAILED:
            return {
                ...state,
                documentsLoaded: false,
                documentsLoading: false,
                submissionDocuments: []
            }
        case CLEAR_SUBMISSION_DOCUMENTS_LIST:
            return {
                ...state,
                documentsLoading: false,
                documentsLoaded: false,
                submissionDocuments: []
            }
        case GET_RELATED_SUBMISSIONS:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                errorGetRelatedSubmissions: null,
                errorGetSubmissionDocument: null
            }
        case GET_RELATED_SUBMISSIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                relatedSubmissions: payload
            }
        case GET_RELATED_SUBMISSIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                errorGetRelatedSubmissions: payload
            }
        case GET_DOCUMENT:
            return {
                ...state,
                errorGetDocuments: null
            }
        case GET_DOCUMENT_SUCCESS:
            return {
                ...state
            }
        case GET_DOCUMENT_FAILED:
            return {
                ...state,
                errorGetDocuments: payload
            }

        case GET_GENERATE_DEFICIENT_TEMPLATE:
            return {
                ...state,
                deficientTemplateGenerating: true,
                doSearchAgain: false
            }
        case GET_GENERATE_DEFICIENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                deficientTemplateGenerating: false,
                doSearchAgain: true
            }
        case GET_GENERATE_DEFICIENT_TEMPLATE_FAILED:
            return {
                ...state,
                deficientTemplateGenerating: false,
                errorGeneratingDeficientTemplate: payload,
                doSearchAgain: false
            }

        case GET_DEFICIENCY_COUNT:
            return {
                ...state,
                deficiencyCounting: true,
                deficiencyCount: null
            }
        case GET_DEFICIENCY_COUNT_SUCCESS:
            return {
                ...state,
                deficiencyCounting: false,
                deficiencyCount: payload,
                deficiencyCountFailed: false,
            }
        case GET_DEFICIENCY_COUNT_FAILED:
            return {
                ...state,
                deficiencyCounting: false,
                deficiencyCountFailed: true,
                deficiencyCount: null
            }
        case SET_SHOW_USER_SUBMISSIONS:
            return {
                ...state,
                showUserSubmissions: payload
            }
        case BATCH_APPROVAL_OPTIONS_BY_ID:
            return {
                ...state,
                isBatchApprovalProcessLoading: true,
                batchApprovalOptionsResponse:false,
            }
        case BATCH_APPROVAL_OPTIONS_BY_ID_SUCCESS:
            return {
                ...state,
                isBatchApprovalProcessLoading: false,
                batchApprovalOptionsResponse: true,
            }
        case BATCH_APPROVAL_OPTIONS_BY_ID_FAILED:
            return {
                ...state,
                isBatchApprovalProcessLoading: false,
                error: payload,
                batchApprovalOptionsResponse: false
            }
        default:
            return state;
    }
}

export default handleAction;