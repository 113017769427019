import {
    IFilterDTO,
    ISubmissionCriteria,
    IDocumentListCriteria,
    IClaimCountsDTO,
    IBatchApprovalOptionsCriteria
} from '../../types/types';

import {
    ISorter
} from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import authService from '../../components/api-authorization/AuthorizeService';
import { createFilterCriteria } from '../../utilities/filterCriteria';
import download from "downloadjs"

export const getCaseSubmissions = async (filter: IFilterDTO[],
    caseId: string, institutionId: number,
    userId: number,
    isUserSubmission: boolean,
    sorter?: ISorter,
    pageNumber?: number,
    rowsPerPage?: number) => {
    try {
        let criteria = createFilterCriteria(filter) as ISubmissionCriteria;
        const token = await authService.getAccessToken();

        if (criteria.dateOfSubmissionStart)
            criteria.dateOfSubmissionStart = criteria.dateOfSubmissionStart?.replace("00:00:00.000Z", "00:00:01.000Z").replace(".000Z", "");   

        if (criteria.dateOfSubmissionEnd) 
            criteria.dateOfSubmissionEnd = criteria.dateOfSubmissionEnd?.replace("00:00:00.000Z", "23:59:59.000Z").replace(".000Z", "");        

        //if institutionid is -1 (Epiq), don't add it to the criteria, so we'll load everything
        //if it's any other institution, lock down the search
        if (institutionId && institutionId !== -1) 
            criteria.institutionId = institutionId;        

        criteria.userId = userId;
        criteria.isUserSubmissions = isUserSubmission;

        if (sorter) {
            criteria.sortField = sorter.property as string;
            criteria.sortDirection = sorter.order;
        }

        if (pageNumber) 
            criteria.pageNumber = pageNumber;        

        if (rowsPerPage)
            criteria.rowsPerPage = rowsPerPage;        

        let response = await fetch(`api/Submission/${caseId}/submissions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok) 
            throw new Error("Failed to get case submissions list.");        

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getMailingSubmissions = async (filter: IFilterDTO[],
    caseId: string, institutionId: number,
    userId: number,
    isUserSubmission: boolean,
    sorter?: ISorter,
    pageNumber?: number,
    rowsPerPage?: number) => {
    try {
        let criteria = createFilterCriteria(filter) as ISubmissionCriteria;
        const token = await authService.getAccessToken();

        if (criteria.dateOfSubmissionStart)
            criteria.dateOfSubmissionStart = criteria.dateOfSubmissionStart?.replace("00:00:00.000Z", "00:00:01.000Z").replace(".000Z", "");

        if (criteria.dateOfSubmissionEnd)
            criteria.dateOfSubmissionEnd = criteria.dateOfSubmissionEnd?.replace("00:00:00.000Z", "23:59:59.000Z").replace(".000Z", "");

        criteria.userId = userId;
        criteria.isUserSubmissions = isUserSubmission;

        if (sorter) {
            criteria.sortField = sorter.property as string;
            criteria.sortDirection = sorter.order;
        }

        if (pageNumber)
            criteria.pageNumber = pageNumber;

        if (rowsPerPage)
            criteria.rowsPerPage = rowsPerPage;

        let response = await fetch(`api/Submission/${caseId}/mailings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok)
            throw new Error("Failed to get mailing submissions list.");

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};


export const exportCaseSubmissions = async (filter: IFilterDTO[],
    caseId: string,
    isNominee: boolean,
    institutionId: number,
    userId: number,
    isUserSubmission: boolean,
    sorter?: ISorter) => {
    try {
        let criteria = createFilterCriteria(filter) as ISubmissionCriteria;
        const token = await authService.getAccessToken();

        let nominee: number = isNominee ? 1 : 0;

        if (criteria.dateOfSubmissionEnd) 
            criteria.dateOfSubmissionEnd = criteria.dateOfSubmissionEnd?.replace("00:00:00.000Z", "23:59:59.000Z");        

        //if institutionid is -1 (Epiq), don't add it to the criteria, so we'll load everything
        //if it's any other institution, lock down the search
        if (institutionId && institutionId !== -1) 
            criteria.institutionId = institutionId;        

        criteria.userId = userId;
        criteria.isUserSubmissions = isUserSubmission;

        if (sorter) {
            criteria.sortField = sorter.property as string;
            criteria.sortDirection = sorter.order;
        }

        let response = await fetch(`api/Submission/${caseId}/export/${nominee}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok) 
            throw new Error("Failed to export case submissions.");        

        let blob = await response.blob();
        let file = new Blob([blob], { type: 'application/octet-stream' });
        let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
        download(file, fileName, "application/octet-stream");
    }
    catch (error) {
        console.error(error);

        throw error;
    }
};

export const getSubmissionDocument = async (submissionId: number) => {
    const token = await authService.getAccessToken();

    try {
        let response = await fetch(`api/submission/getSubmissionDocument/${submissionId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) 
            throw new Error("Failed to get submission document.");        

        let blob = await response.blob();
        let file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
        download(file, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }

    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSubmissionDetailById = async (id: string, getOriginalSubmission: boolean = true ) => {
    try {
        const token = await authService.getAccessToken();

        if (!token) {
            console.log("Bearer token can't be empty.")
            return null;
        }

        let endPoint = `api/Submission/${id}`;

        if (getOriginalSubmission) {
            endPoint += '/original';
        }

        let response = await fetch(endPoint,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok) {
            if (response.status >= 400 && response.status <= 499) {
                document.location.href = "/errorPage";
                return null;
            }

            throw new Error("Failed to get submission detail by Id.");
        }

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getInstitutionDetailsById = async (institutionId: number) => {
    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/Institution/InstitutionId/${institutionId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getUserDetailsById = async (userId: number) => {
    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/User/UserInfoById/${userId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getDeficiencyCount = async (submissionId: number) => {
    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/Submission/deficiencyCounts/${submissionId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSubmissionDocumentsList = async (submissionId: number, filter: IFilterDTO[]) => {
    const token = await authService.getAccessToken();

    try {
        let criteria = createFilterCriteria(filter) as IDocumentListCriteria;

        if (criteria.submittedOn)
            criteria.submittedOn = criteria.submittedOn?.replace("00:00:00.000Z", "00:00:01.000Z").replace(".000Z", "");    

        let response = await fetch(`api/Submission/GetSubmissionDocumentsList/${submissionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok) 
            throw new Error(response.statusText);
        
        return response.json();
    }

    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getRelatedSubmissionsAsync = async (payload: any) => {
    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/Submission/relatedsubmissions/${payload.submissionId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok) 
            throw new Error("Failed to get related submission list.");        

        return response.json();
    }

    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getDocumentByIdAsync = async (documentId: number) => {
    const token = await authService.getAccessToken();

    try {
        let response = await fetch(`api/document/${documentId}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/octet-stream",
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) 
            throw new Error("Failed to get document by Id.");        

        let blob = await response.blob();
        let file = new Blob([blob], { type: 'application/octet-stream' });
        let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
        download(file, fileName, "application/octet-stream");
    }

    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getGenerateDeficientTemplateAsync = async (caseId: number, submissionId: number) => {
    const token = await authService.getAccessToken();

    try {
        let response = await fetch(`api/Document/generateDeficientTemplate/${caseId}/${submissionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok)
            throw new Error("Failed to generate deficient template.");

        return await response.json();
    }

    catch (exception) {
        console.error(exception)
        throw exception;
    }
};

export const getClaimCountsForSubmission = async (submissionId: number): Promise<IClaimCountsDTO | null> => {
    try {
        const token = await authService.getAccessToken();

        if (!token) {
            console.log("Bearer token can't be empty.")
            return null;
        }

        let response = await fetch(`api/claimant/GetClaimStatusCountsForSubmission?submissionId=${submissionId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok) {
            if (response.status >= 400 && response.status <= 499) {
                document.location.href = "/errorPage";
                return null;
            }

            throw new Error(`Failed to get claim counts for submission ID ${submissionId}.`);
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSummedClaimCountsForSubmission = async (submissionId: number): Promise<IClaimCountsDTO | null> => {
    try {
        const token = await authService.getAccessToken();

        if (!token) {
            console.log("Bearer token can't be empty.")
            return null;
        }

        let response = await fetch(`api/claimant/GetSummedClaimStatusCountsForSubmission?submissionId=${submissionId}`,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

        if (!response.ok) {
            if (response.status >= 400 && response.status <= 499) {
                document.location.href = "/errorPage";
                return null;
            }

            throw new Error(`Failed to get claim counts for submission ID ${submissionId}.`);
        }

        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};

export const batchApprovalOptionsById = async (submissionId: number, criteria: IBatchApprovalOptionsCriteria) => {
    const token = await authService.getAccessToken();

    try {        

        let response = await fetch(`api/Submission/BatchApprovalBySubmissionIdAsync/${submissionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();
    }

    catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSubmissionRejectionsBySubmissionId = async (submissionId: number) => {
    const token = await authService.getAccessToken();

    try {
            let response = await fetch(`api/Submission/GetSubmissionRejectionsBySubmissionIdAsync/${submissionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok)
                throw new Error(response.statusText);

            return response.json();
        }
   catch (error) {
            console.error(error);
            throw error;
    }

};

export const abandonSubmission = async (submissionId: number) => {
    try {
        const token = await authService.getAccessToken();

        if (!token) {
            console.log("Bearer token can't be empty.")
            return null;
        }

        let response = await fetch(`api/Submission/abandon/${submissionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            if (response.status >= 400 && response.status <= 499) {
                document.location.href = "/errorPage";
                return null;
            }

            throw new Error(`Failed to abandon submission ID ${submissionId}.`);
        }

        return;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
