import "./custom.css";

import { EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin, EpiqUser } from "./constants/roles";

import { Switch, useHistory } from "react-router-dom";
import { UserContext, UserContextType } from "./modules/User/UserContext";

import Admin from "./modules/Admin/Admin";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeAndAllowRoute from "./components/api-authorization/AuthorizeAndAllowRoute";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import CaseDetailPage from "./modules/Cases/CaseDetailPage";
import CaseListPage from "./modules/Cases/CaseListPage";
import { Dashboard } from "./modules/Dashboard/Dashboard";
import ErrorPage from "./components/Shared/ErrorPage";
import InstitutionDetailPage from "./modules/Institutions/InstitutionDetailPage";
import InstitutionListPage from "./modules/Institutions/InstitutionListPage";
import { Layout } from "./components/Layout";
import { useContext, useEffect } from "react";
import RegistrationRequestsPage from "./modules/Users/RegistrationRequestsPage";
import RegistrationReviewUser from "./modules/Users/RegistrationReviewUser";
import Reports from "./components/Reports";
import { Route } from "react-router";
import SubmissionDetailPage from "./modules/Submissions/SubmissionDetailPage";
import SubmissionListPage from "./modules/Submissions/SubmissionListPage";
import TemplateDetailPage from "./modules/Templates/TemplateDetailPage";
import TemplateListPage from "./modules/Templates/TemplateListPage";
import UserListPage from "./modules/Users/UserListPage";
import TermsOfUsePage from "./modules/TermsOfUse/TermsOfUsePage";
import { signal } from "@preact/signals-react";
import { parseBooleanValue } from "./utilities/utilities";
import MailingListPage from "./modules/Mailings/MailingListPage";
import { AuthorizeService } from "./components/api-authorization/AuthorizeService";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";

export const mailingSubmissionsFeatureEnabled = signal(false);
export const mailingFileMaxSizeMb = signal(0);

export default function App() {
	const { isAuthenticated, isInitialized } = useContext(UserContext) as UserContextType;
	const history = useHistory();

	useEffect(() => {
		const populateState = async () => {
			try {
				if (!isAuthenticated) {
					return;
				}

				const service = new AuthorizeService();
				const token = await service.getAccessToken();

				// if we weren't able to retrieve the token, then just return
				if (!token) {
					return;
				}

				const response = await fetch(
					`api/Configuration/GetConfigValueByKey/mailingSubmissionsFeatureEnabled`,
					{
						method: "GET",
						headers: {
							"Authorization": `Bearer ${token}`
						}
					});

				if (!response.ok) {
					throw new Error(response.statusText);
				}

				const configuration = await response.json();
				const value = parseBooleanValue(configuration.value);
				mailingSubmissionsFeatureEnabled.value = value;

				const responseFileSize = await fetch(`api/Configuration/GetConfigValueByKey/mailingFileMaxSizeMb`,
					{
						method: "GET",
						headers: {
							"Authorization": `Bearer ${token}`
						}
					});

				if (!responseFileSize.ok) {
					throw new Error(response.statusText);
				}

				const configurationFileSize = await responseFileSize.json();
				const valueFileSize = parseInt(configurationFileSize.value, 10);
				mailingFileMaxSizeMb.value = valueFileSize;
			}

			// eat the error
			catch (error) {
				console.log(error);
			}
		};

		populateState();
	},
		[isAuthenticated]);

	useEffect(() => {
		if (typeof window !== "undefined" && isInitialized && isAuthenticated && (window as Window).location.href === (window as Window).location.origin + "/") {
			history.push("/Dashboard");
		}

		if (typeof window !== "undefined" && isInitialized && !isAuthenticated && (window as Window).location.href === (window as Window).location.origin + "/") {
			history.push(ApplicationPaths.Login);
		}

	},
		[isInitialized, isAuthenticated, history]);

	return (
		<Layout>
			<Switch>
				<Route exact path="/" component={ApiAuthorizationRoutes} />
				<AuthorizeRoute exact path="/CaseList" component={CaseListPage} />
				<AuthorizeRoute exact path="/SubmissionList" component={SubmissionListPage} />
				<AuthorizeRoute exact path="/MailingList" component={MailingListPage} />
				<AuthorizeRoute exact path="/CaseDetail/:caseId/:tabName?" component={CaseDetailPage} />
				<AuthorizeRoute exact path="/Dashboard" component={Dashboard} />
				<AuthorizeRoute path="/SubmissionDetail/:id/:tabName?" component={SubmissionDetailPage} />
				<AuthorizeRoute exact path="/SubmissionList/:type" component={SubmissionListPage} />
				<Route exact path="/TermsOfUse" component={TermsOfUsePage} />

				<AuthorizeAndAllowRoute exact path="/Admin" component={Admin}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/UserList" component={UserListPage}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/TemplateDetail/:id/:tabName?" component={TemplateDetailPage}
					allowedRoles={[EpiqUser, EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin]} />
				<AuthorizeAndAllowRoute exact path="/TemplateList" component={TemplateListPage}
					allowedRoles={[EpiqUser, EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin]} />
				<AuthorizeAndAllowRoute exact path="/InstitutionDetail/:id" component={InstitutionDetailPage}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/InstitutionList" component={InstitutionListPage}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/ReviewDetail/:registrationId" component={RegistrationReviewUser}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/RegistrationRequests" component={RegistrationRequestsPage}
					allowedRoles={[EpiqAdmin, EpiqProjectManager, EpiqTechSupport]} />
				<AuthorizeAndAllowRoute exact path="/Reports" component={Reports}
					allowedRoles={[EpiqUser]} />
				<Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
				<Route path="/errorPage" component={ErrorPage} />
				<Route path="/*" component={ErrorPage} />
			</Switch>
		</Layout>
	);
}