import { AlertType } from '../../types/enums';

import {
    BATCH_APPROVAL_OPTIONS_BY_ID,
    DOWNLOAD_SUBMISSION,
    GET_CASE_SUBMISSIONS,
    GET_MAILING_SUBMISSIONS,
    CLEAR_CASE_SUBMISSIONS_LIST,
    CLEAR_MAILING_SUBMISSIONS_LIST,
    GET_INSTITUTION_DETAILS_BY_ID,
    GET_RELATED_SUBMISSIONS,
    GET_SUBMISSION_DETAIL_BY_ID,
    GET_SUBMISSION_DOCUMENTS_LIST,
    GET_USER_DETAILS_BY_ID,
    GET_DOCUMENT,
    GET_DEFICIENCY_COUNT,
    batchApprovalOptionsByIdSuccess,
    batchApprovalOptionsByIdFailed,
    getDeficiencyCountFailed,
    getDeficiencyCountSuccess,
    getCaseSubmissionsFailed,
    getCaseSubmissionsSuccess,
    clearCaseSubmissionsListAction,
    getMailingSubmissionsFailed,
    getMailingSubmissionsSuccess,
    clearMailingSubmissionsListAction,
    mailingSubmissionsListNeedsRefreshAction,
    getInstitutionDetailsByIdFailed,
    getInstitutionDetailsByIdSuccess,
    getRelatedSubmissionsFailed,
    getRelatedSubmissionsSuccess,
    getSubmissionDetailByIdFailed,
    getSubmissionDetailByIdSuccess,
    getSubmissionDocumentFailedAction,
    getSubmissionDocumentSuccessAction,
    getSubmissionDocumentsListFailedAction,
    getSubmissionDocumentsListSuccessAction,
    clearSubmissionDocumentsListAction,
    getUserDetailsByIdFailed,
    getUserDetailsByIdSuccess,
    getDocumentSuccessAction,
    getDocumentFailedAction,
    exportCaseSubmissionsSuccess,
    exportCaseSubmissionsFailed,
    EXPORT_CASE_SUBMISSIONS,
    CLEAR_SUBMISSION_DOCUMENTS_LIST,
    getGenerateDeficientTemplateSuccess,
    getGenerateDeficientTemplateFailed,
    GET_GENERATE_DEFICIENT_TEMPLATE,
    MAILING_SUBMISSIONS_LIST_NEEDS_REFRESH,
    ABANDON_SUBMISSION,
    abandonSubmissionSuccess,
    abandonSubmissionFailed
} from './submissions.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import {
    getCaseSubmissions,
    getMailingSubmissions,
    exportCaseSubmissions,
    getInstitutionDetailsById,
    getRelatedSubmissionsAsync,
    getSubmissionDetailById,
    getSubmissionDocument,
    getSubmissionDocumentsList,
    getUserDetailsById,
    getDocumentByIdAsync,
    getGenerateDeficientTemplateAsync,
    getDeficiencyCount,
    batchApprovalOptionsById,
    abandonSubmission
} from './submissions.api';

import { showAlert } from '../Alerts/alerts.actions';
import { IDeficiencyCount, ISubmissionListResponse } from '../../types/types';

function* getCaseSubmissionsSaga({ payload: { filter, caseId, institutionId, userId, isUserSubmission, sorter, pageNumber, rowsPerPage } }: any): any {
    try {
        const response = yield call(getCaseSubmissions, filter, caseId, institutionId, userId, isUserSubmission, sorter, pageNumber, rowsPerPage);
        yield put(getCaseSubmissionsSuccess(response as ISubmissionListResponse));
    } catch (error: any) {
        yield put(getCaseSubmissionsFailed(error.message));
    }
}

function* getCaseSubmissionsWatcher() {
    yield takeLeading(GET_CASE_SUBMISSIONS, getCaseSubmissionsSaga);
}

function* clearCaseSubmissionsListSaga(): any {
    yield put(clearCaseSubmissionsListAction());
}

function* clearCaseSubmissionsListWatcher() {
    yield takeLeading(CLEAR_CASE_SUBMISSIONS_LIST, clearCaseSubmissionsListSaga);
}

function* getMailingSubmissionsSaga({ payload: { filter, caseId, institutionId, userId, isUserSubmission, sorter, pageNumber, rowsPerPage } }: any): any {
    try {
        const response = yield call(getMailingSubmissions, filter, caseId, institutionId, userId, isUserSubmission, sorter, pageNumber, rowsPerPage);
        yield put(getMailingSubmissionsSuccess(response as ISubmissionListResponse));
    } catch (error: any) {
        yield put(getMailingSubmissionsFailed(error.message));
    }
}

function* getMailingSubmissionsWatcher() {
    yield takeLeading(GET_MAILING_SUBMISSIONS, getMailingSubmissionsSaga);
}

function* clearMailingSubmissionsListSaga(): any {
    yield put(clearMailingSubmissionsListAction());
}

function* clearMailingSubmissionsListWatcher() {
    yield takeLeading(CLEAR_MAILING_SUBMISSIONS_LIST, clearMailingSubmissionsListSaga);
}

function* mailingSubmissionsListNeedsRefreshSaga(): any {
    yield put(mailingSubmissionsListNeedsRefreshAction());
}

function* mailingSubmissionsListNeedsRefreshWatcher() {
    yield takeLeading(MAILING_SUBMISSIONS_LIST_NEEDS_REFRESH, mailingSubmissionsListNeedsRefreshSaga);
}

function* abandonSubmissionSaga(action: any): any {
    try { 
        yield call(abandonSubmission, action.submissionId);
        yield put(abandonSubmissionSuccess());
        yield put(showAlert({
            hidden: false,
            message: "Submission successfully set to Abandoned status.",
            severity: AlertType.success
        }));
    } catch (error: any) {
        yield put(abandonSubmissionFailed(error.message));
        yield put(showAlert({
            hidden: false,
            message: "Unable to set submission to Abandoned status.",
            severity: AlertType.error
        }));
    }


}

function* abandonSubmissionWatcher() {
    yield takeLeading(ABANDON_SUBMISSION, abandonSubmissionSaga);
}

function* getSubmissionDocumentSaga(payload: any): any {
    try {
        yield call(getSubmissionDocument, payload.submissionId);
        yield put(getSubmissionDocumentSuccessAction());
    } catch (error: any) {
        yield put(getSubmissionDocumentFailedAction(error.message));
        yield put(showAlert({
            hidden: false,
            message: "There was a problem downloading the file. Please try again in a few minutes.",
            severity: AlertType.error
        }));
    }
}

function* getSubmissionDocumentWatcher() {
    yield takeLeading(DOWNLOAD_SUBMISSION, getSubmissionDocumentSaga);
}

function* getSubmissionDetailSaga({ payload: { id } }: any): any {
    try {
        const submissionDetail = yield call(getSubmissionDetailById, id);
        yield put(getSubmissionDetailByIdSuccess(submissionDetail));
    }

    catch (error: any) {
        yield put(getSubmissionDetailByIdFailed(error.message));
    }
}

function* getSubmissionDetailWatcher() {
    yield takeLeading(GET_SUBMISSION_DETAIL_BY_ID, getSubmissionDetailSaga);
}

function* getInstitutionDetailsByIdSaga(payload: any): any {
    try {
        const institutionDetail = yield call(getInstitutionDetailsById, payload.payload);
        yield put(getInstitutionDetailsByIdSuccess(institutionDetail));
    } catch (error: any) {
        yield put(getInstitutionDetailsByIdFailed(error.message));
    }
}

function* getInstitutionDetailsByIdWatcher() {
    yield takeLeading(GET_INSTITUTION_DETAILS_BY_ID, getInstitutionDetailsByIdSaga);
}

function* getUserDetailsByIdSaga(payload: any): any {
    try {
        const userSlideoutDetail = yield call(getUserDetailsById, payload.payload);
        yield put(getUserDetailsByIdSuccess(userSlideoutDetail));
    } catch (error: any) {
        yield put(getUserDetailsByIdFailed(error.message));
    }
}

function* getUserDetailsByIdWatcher() {
    yield takeLeading(GET_USER_DETAILS_BY_ID, getUserDetailsByIdSaga);
}

function* getDeficiencyCountSaga(payload: any): any {
    try {
        const count: IDeficiencyCount = yield call(getDeficiencyCount, payload.payload);
        yield put(getDeficiencyCountSuccess(count));
    } catch (error: any) {
        yield put(getDeficiencyCountFailed(error.message));
    }
}

function* getDeficiencyCountWatcher() {
    yield takeLeading(GET_DEFICIENCY_COUNT, getDeficiencyCountSaga);
}

function* getSubmissionDocumentsListSaga({ payload: { submissionId, filter } }: any): any {
    try {
        const submissionDocumentsList = yield call(getSubmissionDocumentsList, submissionId, filter);
        yield put(getSubmissionDocumentsListSuccessAction(submissionDocumentsList));
    }
    catch (error: any) {
        yield put(getSubmissionDocumentsListFailedAction(error.message));
        yield put(showAlert({
            hidden: false,
            message: "There was an error retrieving submission documents list.",
            severity: AlertType.error
        }));

        yield put(getSubmissionDocumentsListFailedAction(error.message));
    }
}

function* getSubmissionDocumentsListWatcher() {
    yield takeLeading(GET_SUBMISSION_DOCUMENTS_LIST, getSubmissionDocumentsListSaga);
}

function* getRelatedSubmissionsSaga(payload: any): any {
    try {
        const relatedSubmissionList = yield call(getRelatedSubmissionsAsync, payload);
        yield put(getRelatedSubmissionsSuccess(relatedSubmissionList));
    } catch (error: any) {
        yield put(getRelatedSubmissionsFailed(error.message));
    }
}

function* getRelatedSubmissionsWatcher() {
    yield takeLeading(GET_RELATED_SUBMISSIONS, getRelatedSubmissionsSaga);
}

function* getDocumentSaga(payload: any): any {
    try {
        yield call(getDocumentByIdAsync, payload.documentId);
        yield put(getDocumentSuccessAction());
    } catch (error: any) {
        yield put(getDocumentFailedAction(error.message));
        yield put(showAlert({
            hidden: false,
            message: "There was a problem downloading the file. Please try again in a few minutes.",
            severity: AlertType.error
        }));
    }
}

function* getDocumentWatcher() {
    yield takeLeading(GET_DOCUMENT, getDocumentSaga);
}

function* getGenerateDeficientTemplateSaga({ payload: { caseId, submissionId } }: any): any {
    try {
        let data = yield call(getGenerateDeficientTemplateAsync, caseId, submissionId);
        yield put(getGenerateDeficientTemplateSuccess(data));
    } catch (error: any) {
        yield put(getGenerateDeficientTemplateFailed(error.message));
    }
}

function* getGenerateDeficientTemplateWatcher() {
    yield takeLeading(GET_GENERATE_DEFICIENT_TEMPLATE, getGenerateDeficientTemplateSaga)
}

function* clearSubmissionDocumentsListSaga(payload: any): any {
    yield put(clearSubmissionDocumentsListAction());
}

function* clearSubmissionDocumentsListWatcher() {
    yield takeLeading(CLEAR_SUBMISSION_DOCUMENTS_LIST, clearSubmissionDocumentsListSaga);
}

function* exportCaseSubmissionsSaga({ payload: { filter, caseId, isNominee, institutionId, userId, isUserSubmission, sorter } }: any): any {
    try {
        const response = yield call(exportCaseSubmissions, filter, caseId, isNominee, institutionId, userId, isUserSubmission, sorter);
        yield put(exportCaseSubmissionsSuccess(response as ISubmissionListResponse));
    } catch (error: any) {
        yield put(exportCaseSubmissionsFailed(error.message));
    }
}

function* exportCaseSubmissionsWatcher() {
    yield takeLeading(EXPORT_CASE_SUBMISSIONS, exportCaseSubmissionsSaga);
}


function* batchApprovalOptionsByIdSaga({ payload: { submissionId, criteria } }: any): any {
    try {
        const batchApprovalOptionsByIdResponse = yield call(batchApprovalOptionsById, submissionId, criteria);
        yield put(batchApprovalOptionsByIdSuccess(batchApprovalOptionsByIdResponse));
        yield put(showAlert({
            hidden: false,
            message: batchApprovalOptionsByIdResponse.message,
            severity: batchApprovalOptionsByIdResponse.success
                ? AlertType.success
                : AlertType.error
        }));
    }
    catch (error: any) {
        yield put(batchApprovalOptionsByIdFailed(error.message));
        yield put(showAlert({
            hidden: false,
            message: "batch approval options failed to perform operation.",
            severity: AlertType.error
        })); 

        yield put(batchApprovalOptionsByIdFailed(error.message));
    }
}

function* batchApprovalOptionsByIdWatcher() {
    yield takeLeading(BATCH_APPROVAL_OPTIONS_BY_ID, batchApprovalOptionsByIdSaga);
}

export default function* submissionsSaga() {
    yield all([
        getCaseSubmissionsWatcher(),
        clearCaseSubmissionsListWatcher(),
        getMailingSubmissionsWatcher(),
        clearMailingSubmissionsListWatcher(),
        mailingSubmissionsListNeedsRefreshWatcher(),
        abandonSubmissionWatcher(),
        getSubmissionDocumentWatcher(),
        getSubmissionDetailWatcher(),
        getSubmissionDocumentsListWatcher(),
        getInstitutionDetailsByIdWatcher(),
        getUserDetailsByIdWatcher(),
        getRelatedSubmissionsWatcher(),
        getDocumentWatcher(),
        clearSubmissionDocumentsListWatcher(),
        exportCaseSubmissionsWatcher(),
        getGenerateDeficientTemplateWatcher(),
        getDeficiencyCountWatcher(),
        batchApprovalOptionsByIdWatcher()
    ]);
}